import React, { Component } from "react";
import Api from "api/Api";
import { endpoint } from "api/endpoint";
import ServicesListContext from "./ServicesListContext";

class ServicesListProvider extends Component {
  state = {
    loading: false,
    servicesList: [],
    total: 0,
    offset: 0,
    limit: 10,
    associate_user: "",
    sorTing: ""
  };

  /* componentDidMount() {
    this.get_services({
      offset: 0,
      limit: 9999
    });
  } */

  get_services = (params = {}) => {
    if (params.limit) {
      let associate_user = "";
      if (params.associate_user) {
        associate_user = params.associate_user;
      }
      let sorTing = "";
      if (params.sortField) {
        if (params.sortOrder === "descend") {
          sorTing = `&ordering=-${params.sortField}`;
        } else if(params.sortOrder === "ascend") {
          sorTing = `&ordering=${params.sortField}`;
        } else {
          sorTing = ""
        }
      }
      this.setState(
        { offset: params.offset, limit: params.limit, associate_user, sorTing },
        () => {
          this.get_servicesList();
        }
      );
    } else {
      this.get_servicesList();
    }
  };

  get_servicesList = () => {
    const { offset, limit, associate_user, sorTing } = this.state;
    this.setState({ loading: true });
    Api.get(
      `${endpoint.services}?limit=${limit}&offset=${offset}&ordering=-created_at&owner=${associate_user}${sorTing}`
    )
      .then(response => {
        let data = response.data;
        this.setState({
          loading: false,
          total: data.count,
          servicesList: data.results
        });
      })
      .catch(error => {
        // let data = error.response;
        this.setState({ loading: false });
      });
  };

  render() {
    const { loading, servicesList, total } = this.state;
    return (
      <ServicesListContext.Provider
        value={{
          loading: loading,
          servicesList: servicesList,
          total: total,
          get_services: this.get_services
        }}
      >
        {this.props.children}
      </ServicesListContext.Provider>
    );
  }
}

export default ServicesListProvider;
