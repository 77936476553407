import { CHANGE_LOCALE } from "./actionTypes";
import config from "../../containers/LanguageSwitcher/config";

const locale = localStorage.getItem('locale') || config.defaultLanguage;
const initialState = { locale };

export default function settingsReducer( state = initialState, action ) {
    switch ( action.type ) {
        case CHANGE_LOCALE:
            localStorage.setItem('locale', action.locale );
            return {
                ...state,
                locale: action.locale
            };

        default:
            return state;
    }
}