import moment from "moment";
import React from "react";
import { Translate } from 'components/utility';

/**
 * Returns substring from the second occurrence of character ("/") of the given url/path
 * Intended to get path removing the base path of the given path
 *
 * @function generateActualPath
 * @param {string} path
 * @example
 * generateActualPath("/dashboard/invoice/")
 * @return {string} "/invoice/"
 * */
export const generateActualPath = path => {
  return path.substring(path.indexOf("/", 1));
};

export const servicesUnitList = filter => {
  const list = [
    { name: <Translate id={"packs"} />, value: "Förp" },
    { name: <Translate id={"hour"} />, value: "h" },
    { name:  <Translate id={"kg"} />, value: "kg" },
    { name: <Translate id={"st"} />, value: "st" }
  ];

  if (filter) {
    return list.filter(item => item.value === filter)[0];
  }

  return list;
};

/**
 * Return the percentage of a given value.
 *
 * @param {string|number} value - The value on which percentage will be calculated
 * @param {string|number} percentage - Percentage amount
 * @return {number} Percentage of the given value
 *
 * @example
 *
 *     getPercentage(100, 10)
 */
export const getPercentage = (value, percentage) => {
  return (parseFloat(value) * parseFloat(percentage)) / 100;
};

export const packageList = filter => {
  const list = [
    { name: <Translate id={"standard"} />, value: 1 },
    { name: <Translate id={"premium"} />, value: 2 }
  ];

  if (filter) {
    return list.filter(item => item.value === filter)[0];
  }
  return list;
};

export const formatCurrency = currency =>
  new Intl.NumberFormat("sv-SE", { style: "currency", currency: "SEK" }).format(
    currency
  );

export const checkBankId = (rule, value, callback) => {
  if (!value) {
    callback(<Translate id={"form.requiredMessage"} />);
  } else if (value) {
    if (value.length > 13 || value.length < 13) {
      callback(<Translate id={"form.personNumberValid"} />);
    }
    callback();
    return;
  } else {
    callback(<Translate id={"form.personNumberValid"} />);
  }
};

export const checkOrganizationNumber = (rule, value, callback) => {
  if (!value) {
    callback(<Translate id={"form.requiredMessage"} />);
  } else if (value) {
    if (value.length > 11 || value.length < 11) {
      callback(<Translate id={"form.organizationNumberValid"} />);
    }
    callback();
    return;
  } else {
    callback(<Translate id={"form.organizationNumberValid"} />);
  }
};

/**
 * A simple helper function to generate full name from first_name and last_name.
 *
 * @param {string} first_name - Person First name
 * @param {string} last_name - Person Last Name
 * @return {string} Person full name
 *
 * @example
 *     getFullName("John", "Doe")
 */
export const getFullName = (first_name, last_name) => {
  return `${first_name || "" } ${last_name || ""}`
};

/**
 * A simple helper function to check if a value is an object and not null.
 *
 * @param {any} value - Value
 * @return {boolean} Return true if object and not null otherwise false
 *
 * @example
 *     isObject({ name: "John"})
 *     isObject("John Doe")
 */
export const isObject = value => typeof value === "object" && value !== null;

/**
 * A simple function to convert date string to given format or default format.
 *
 * @param {string} dateString - Date String
 * @param {string} format - Date Format
 * @return {string} Return formatted Date
 *
 * @example
 *  formatDate("2019-12-26T09:20:57.345578Z");
 */
export const formatDate = (dateString, format = "YYYY-MM-DD") =>
dateString ? moment(dateString).format(format):'';

/**
 * ellipseText truncate given text at given position and returns text with ellipsis. If the text length is smaller than
 * the length then the given text will be returned.
 *
 * @param {string} text - A long text
 * @param {number} length - Text truncates position
 * @return {string} Return Truncated text with ellipsis
 *
 * @example
 *  ellipseText("Enthusiastically disintermediate emerging supply chains before economically sound value. Interactively
 *  procrastinate one-to-one technologies rather than 2.0 synergy. Phosfluorescently empower multimedia based
 *  information before state of.");
 */
export const ellipseText = (text, length = 100) =>
  text.length <= length ? text : `${text.substr(0, length)}...`;

export const invoiceServiceTotal = (quantity, price, discount, vat) => {
  //Total before discount and vat
  let total = parseFloat(quantity, 10) * parseFloat(price);

  //Delete discount from total
  total -= getPercentage(total, discount);

  //Add vat to total
  // total += getPercentage(total, vat);
  total += getPercentage(total, 0);

  return total;
};

/**
 * objectToUrlParams converts an object to URL params
 *
 * @param {object} obj - Object
 *
 * @example
 *  objectToUrlParams({
 *      "first_name": "John",
 *      "last_name": "Doe"
 *  })
 *
 *  @return { string }
 *  first_name=JOhn&last_name=Doe
 */
export const objectToUrlParams = obj => {
  const urlParams = [];

  Object.keys( obj ).map( key => {
    //Check if object key has value, if not the key will be skipped
    if( obj[key] ){
      //Check if it's a moment type value, then convert it to relative format
      if(moment.isMoment( obj[key ])){
        urlParams.push(`${key}=${moment(obj[key]).format("YYYY-MM-DD")}`)
      }else{
        urlParams.push(`${key}=${obj[key]}`)
      }
    }
  });

  return urlParams.join('&');
};

export const validationSalaryCost = (available_balance, rule, value, callback) => {
  if (!value) {
    callback(<Translate id={"form.requiredMessage"} />);
  } else if (!isNaN(value)) {
    if (parseFloat(available_balance) < parseFloat(value)) {
      callback(<Translate id={"form.salaryCostValidation"} />);
    }
    callback();
    return;
  } else {
    callback(<Translate id={"form.requiredMessage"} />);
  }
};

export const calculateSocialCost = (person_number,callback) => {
  if(person_number){
    const born = person_number.substring(0, 4);
    let socialCost = {label: '0', value: 0};
    if(born > 1953){
      socialCost.label = "31,42";
      socialCost.value = 23.9081;
    } else {
      socialCost.label = "10,21";
      socialCost.value = 9.265;
    }
    callback(socialCost)
  }
}

export const formHasErrors = (fieldsError) => {
  return Object.keys(fieldsError).some(field => fieldsError[field]);
}


export const checkHours = (rule, value, callback) => {
  if (!value) {
    callback(<Translate id={"form.requiredMessage"} />);
  } else if (!isNaN(value)) {
    callback();
    return;
  } else {
    callback(<Translate id={"form.hoursValid"} />);
  }
};

export const expenditureAmountValidation = (available_balance, rule, value, callback) => {
  if (!value) {
    callback(<Translate id={"form.requiredMessage"} />);
  } else if (!isNaN(value)) {
    if (parseFloat(available_balance) < parseFloat(value)) {
      callback(<Translate id={"form.expenditureAmountValidation"} />);
    }
    callback();
    return;
  } else {
    callback(<Translate id={"form.requiredMessage"} />);
  }
};

export const formatDateTime = (dateString, format = "YYYY-MM-DD HH:mm") =>
dateString ? moment(dateString).format(format):'';

export const calculateAccommodation = (type,fromDate,toDate,fromTime,toTime,days_left,callback) => {
  let sek = 0;
  if (type == 1) {
    if(fromDate === toDate){
      if(fromTime > '12:00' && toTime < '19:00'){
        sek += 120;
      }else if(fromTime <= '12:00' && toTime >= '19:00'){
        sek += 240;
      }
    } else if(fromDate != toDate){
      if(fromTime > '12:00'){
        sek += 120;
      }else if(fromTime <= '12:00'){
        sek += 240;
      }
      if(toTime < '19:00'){
        sek += 120;
      }else if(toTime >= '19:00'){
        sek += 240;
      }
      if(days_left > 1){
        sek += (240*Number(days_left-1));
      }
    }
  } else if (type == 2 ){ //self
    if(days_left == 0){
      sek += (120*Number(days_left+1));
    } else{
      if(toTime >= '19:00'){
        sek += (120*Number(days_left+1));
      }else {
        sek += (120*Number(days_left));
      }
    }
  }
  
  return callback(sek);
}

export const getCustomerName = (customer) => {
  if(customer){
    const {type,company_name,first_name,last_name} = customer;
    if(type===2){
      return `${company_name || "" }`
    }else if(type===1){
      return `${first_name || "" } ${last_name || ""}`
    } 
  } else {
    return '';
  }
};


export const organizationNumberFormating = (value,callback) => {
  if(value){
    value = value.replace(/[^\d0-9]/g, '').replace(/(.{6})/g, '$1-').trim();
    return callback(value);
  } else{
    return '';
  }
}

export const personNumberFormating = (value,callback) => {
  if(value){
    value = value.replace(/[^\d0-9]/g, '').replace(/(.{8})/g, '$1-').trim();
    return callback(value);
  } else{
    return '';
  }
}

export const isEmptyObject = (obj) => {
  return Object.keys(obj).length === 0;
}