import actions from "./actions";

const initState = { idToken: null, user_type: 0, user_id: 0 };

export default function authReducer(state = initState, action) {
  switch (action.type) {
    case actions.LOGIN_SUCCESS:
      return {
        ...state,
        idToken: action.token,
        user_type: action.user_type,
        user_id: action.user_id
      };
    case actions.LOGOUT:
      return initState;
    default:
      return state;
  }
}
