import React from "react";
import { Link } from "react-router-dom";
import { siteConfig } from "../../settings";
import logo from "image/logo-white.png";
import styled from "styled-components";


export default ({ collapsed, toggleCollapsed }) => {
  return (
    <div className="isoLogoWrapper">
      {collapsed ? (
        <div>
          <h3>
            <a href="#" style={{ marginTop: 20 }}>
              <i className={siteConfig.siteIcon} />
            </a>
          </h3>
        </div>
      ) : (
        <Brand>
          <Link to="/dashboard">
            {/*{siteConfig.siteName}*/}
             <img src={logo} alt={"Bolags Bolaget"}/>
          </Link>
          {/* <Icon
            type="arrow-right"
            onClick={toggleCollapsed}
            style={{ color: "#fff" }}
          /> */}
        </Brand>
      )}
    </div>
  );
};

const Brand = styled.h3`
  margin-bottom: 0;
  
  img{
   vertical-align: bottom;
   height: 70px;
   margin-top: 20px;
  }
`;