import defaultTheme from "./themedefault";
import clone from "clone";

const theme = clone(defaultTheme);
theme.palette.primary = [
  "#E52513", //red
  "#EF7C71"
];
theme.palette.secondary = [
  "#333951",
  "#323A45",
  "#5B616B",
  "#AEB0B5",
  "#F1F1F1",
  "rgba(229, 45, 16, 0.09)",
  "#FEF6E5"
];
theme.palette.warning = ["#F2A900", "#FFCA28"];
theme.palette.border = [
  "#AEB0B5",
  "#E52513", //red
  "#E5E5E5"
];
theme.palette.text = [
  "#666666", //black 60
  "#333951",
  "#E52513", //red
  "#FFFFFF", //white
  "#EF7C71",
  "#262626",
  "#585858",
  "#E87722"
];
theme.palette.shadow = ["rgba(229, 37, 19, 0.14901960784313725)"];
export default theme;
