import App from "./app/reducer";
import Auth from "./auth/reducer";
import Customers from "./customers/reducer";
import Services from "./services/reducer";
import SelfEmployed from "./self-employed/reducer";
import Profile from "./profile/reducer";
import Settings from "./settings/reducer";

export default {
  Auth,
  App,
  Customers,
  Services,
  SelfEmployed,
  Profile,
  Settings
};
