import styled from "styled-components";
import { palette, font } from "styled-theme";
import { transition, borderRadius } from "./settings/style-util";

const DashAppHolder = styled.div`
  font-family: ${font("primary", 0)};

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  a,
  p,
  li,
  input,
  textarea,
  span,
  div,
  img,
  svg {
    &::selection {
      background: ${palette("primary", 0)};
      color: #fff;
    }
  }

  .ant-row {
    ${"" /* margin-left: -8px;
    margin-right: -8px; */};
  }

  .ant-row > div {
    padding: 0;
  }
  .isoInputWrapper {
    .ant-form-item {
      margin-bottom: 35px;
      input {
        width: calc(100% - 10px);
      }
    }
  }
  .isoLeftRightComponent {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }

  .isoCenterComponent {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
  }
  .ant-layout {
    background: #fff;
  }

  .ant-input {
    font-size: 14px;
  }

  .ant-spin-dot-item {
    background-color: ${palette("primary", 0)};
  }

  .ant-form-item {
    margin-bottom: 18px;
    .ant-form-item-control{
      .ant-input, .ant-input-number-input {
        height: 45px;
        padding: 6px 10px;
        font-size: 14px;
        line-height: 1.5;
        color: ${palette("text", 0)};
        background-color: #fff;
        background-image: none;
        border: 1px solid ${palette("border", 0)};
        ${borderRadius("4px")};
        ${transition()};
  
        &[disabled] {
          background-color: #f5f5f5;
        }
      }
    } 

    .ant-select-selection--single {
      height: 44px !important;
      .ant-select-selection__rendered {
        line-height: 40px;
        .ant-select-selection-selected-value {
          color: ${palette("text", 0)};
        }
      }
    }
    .has-error .ant-form-explain,
    .has-error .ant-form-split {
      margin-top: 1px;
    }
    .ant-form-item-label > label::before {
      display: none;
      content: "";
    }
    .ant-form-item-label > label::after {
      display: none;
      content: "";
    }
    .ant-form-item-label > label.ant-form-item-required::after {
      margin-left: 4px;
      display: inline-block;
      color: #f5222d;
      font-size: 14px;
      font-family: SimSun, sans-serif;
      line-height: 1;
      content: "*";
    }
  }
  .formWithlable {
    .ant-form-item {
      margin-bottom: 20px;
    }
    .ant-form-item-label {
      line-height: 30px;
    }
  }
  .isoLayoutContentWrapper .page-title {
    font-size: 24px;
    margin-bottom: 23px;
  }

  .hide {
    display: none;
  }
  .hide-by-height {
    height: 0;
    overflow: hidden;
  }
  .revenue-chart {
    rect {
      rx: 4;
    }
  }
  .invoice-hint {
    background: rgba(254, 246, 229, 0.9);
    max-width: 100px;
    padding: 10px;
    border-radius: 10px;
    color: #000;
    font-size: 13px;
  }
  .p-0{
   padding: 0 !important; 
  }
  .flex-direction-row{
    flex-direction: row !important;
  }
  .ant-upload-list-item-name{
    color: ${palette("text", 0)};
  }
`;

export default DashAppHolder;
