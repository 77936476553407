import React, { Component } from "react";
import { connect } from "react-redux";
import { Layout } from "antd";
import appActions from "../../redux/app/actions";
import ServicesAction from "redux/services/actions";
import TopbarUser from "./topbarUser";
import TopbarWrapper from "./topbar.style";
import themes from "../../settings/themes";
import { themeConfig } from "../../settings";
import { Menu, Icon } from "antd";
import Button from "../../components/uielements/button";
import Dropdown from "../../components/uielements/dropdown";
import { Link } from "react-router-dom";
import { APP_PATH } from "../App/Router/paths";
import { Translate } from "components/utility";

const { Header } = Layout;
const { toggleCollapsed } = appActions;
const { modal_toggle } = ServicesAction;
const customizedTheme = themes[themeConfig.theme];

/* const CreateSelfEmployed = user_type => {
  if (user_type === 1) {
    return (
      <Menu.Item key="0">
        <Link to={APP_PATH.create_self_employed}>Self-Employed</Link>
      </Menu.Item>
    );
  }
}; */

class Topbar extends Component {
  menu = () => (
    <Menu>
      {/* {CreateSelfEmployed(this.props.user_type)} */}
      <Menu.Item key="1">
        <Link to={APP_PATH.create_customer}><Translate id={"customer"}/></Link>
      </Menu.Item>
      <Menu.Item key="2" onClick={this.props.modal_toggle}>
        <Translate id={"service"}/>
      </Menu.Item>
      <Menu.Item key="3">
        <Link to={APP_PATH.create_invoice}><Translate id={"invoice"}/></Link>
      </Menu.Item>
    </Menu>
  );
  render() {
    const { toggleCollapsed } = this.props;
    const collapsed = this.props.collapsed && !this.props.openDrawer;
    const styling = {
      background: customizedTheme.backgroundColor,
      position: "fixed",
      width: "100%",
      height: 70
    };
    let toggleButton;
    // if (this.props.view === "MobileView") {
    toggleButton = (
      <button
        className={
          collapsed ? "triggerBtn menuCollapsed" : "triggerBtn menuOpen"
        }
        style={{ color: "#000" }}
        onClick={toggleCollapsed}
      />
    );
    // }
    return (
      <TopbarWrapper>
        <Header
          style={styling}
          className={
            collapsed ? "isomorphicTopbar collapsed" : "isomorphicTopbar"
          }
        >
          <div className="isoLeft">{toggleButton}</div>

          <ul className="isoRight">
            <li>
              <Dropdown overlay={this.menu} trigger={["click"]}>
                <Button className="primary">
                  <Translate id={"button.create"}/>{" "}
                  <Icon
                    type="caret-down"
                    style={{ fontSize: "12px", color: "#fff" }}
                  />
                </Button>
              </Dropdown>
            </li>
            <li onClick={() => this.setState({ selectedItem: "user" })}>
              {/* className="isoUser" */}
              <TopbarUser />
            </li>
          </ul>
        </Header>
      </TopbarWrapper>
    );
  }
}

export default connect(
  state => ({
    ...state.App,
    user_type: state.Auth.user_type
  }),
  { toggleCollapsed, modal_toggle }
)(Topbar);
