import React, { useState, useEffect } from 'react';
import StatisticsContext from "./StatisticsContext";
import api, { endpoints } from 'api';

function StatisticsProvider(props) {
    const [ statistics, setStatistics ] = useState({});

    useEffect(() => {
       fetchStatistics();
    }, []);

    const fetchStatistics = () => {
      api.get(endpoints.statistics)
          .then( response => {
              setStatistics( response.data )
          })
          .catch(error => console.log(error))
    };

    return (
        <StatisticsContext.Provider value={ { data: statistics, updateStat: fetchStatistics } }>
            {props.children}
        </StatisticsContext.Provider>
    );
}

export default StatisticsProvider;