import antDesignSwedishTexts from 'antd/lib/locale-provider/sv_SE';
import svMessages from '../locales/sv_SE';

const SwedishTexts = {
  messages: {
    ...svMessages,
  },
  antd: antDesignSwedishTexts,
  locale: 'sv-SE'
};
export default SwedishTexts;
