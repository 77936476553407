import Api from "api/Api";
import { endpoint } from "api/endpoint";

const actions = {
  SET_USER: "SET_USER",
  get_user: payload => {
    return function(dispatch, store) {
      return Api.get(`${endpoint.users}${payload}/`)
        .then(response => {
          dispatch({
            type: actions.SET_USER,
            payload: response.data
          });
        })
        .catch(error => {
          // let response = error.response;
        });
    };
  },
  set_user: payload => {
    return {
      type: actions.SET_USER,
      payload
    };
  }
};
export default actions;
