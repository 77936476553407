import React, { useState, forwardRef } from "react";
import { AutoComplete, InputSearch} from "components/uielements";
import Api, { endpoints } from "api";
import { useIntl } from "react-intl";

function SelectSelfEmployeds(props, ref) {
  const [selfEmployeds, setSelfEmployeds] = useState([]);
  // const [loading, enableLoading] = useState(false);
  const translate = useIntl().formatMessage;
  const { size, returnName} = props;
  const handleSearch = (value) => {
    if(!value){
      return false;
    }
    // enableLoading(true);
    Api.get(`${endpoints.users}?user_type=2&is_active=true&search=${value}`)
      .then(response => {
        const {
          data: { results }
        } = response;
        const selfEmployedsListSource = results.map(employed => ({
          value: returnName ? `${employed.first_name} ${employed.last_name}` : employed.id,
          text: `${employed.first_name} ${employed.last_name}`
        }));
        setSelfEmployeds(selfEmployedsListSource);
        // enableLoading(false);
      })
      .catch(error => {
        // enableLoading(false);
      });
    
  }

  
  
  return (
    <AutoComplete
      // loading={loading} 
      {...props}
      onSearch={handleSearch}
      dataSource={selfEmployeds}
    >
      <InputSearch size={size} placeholder={`${translate({ id: "search.selfEmployed" })}`}/>
    </AutoComplete>
  );
}

const SelectSelfEmployed = forwardRef(SelectSelfEmployeds);

export default SelectSelfEmployed;
