import React, { useState, forwardRef, useEffect} from 'react';
import { AutoComplete, InputSearch} from "components/uielements";
import Api, { endpoints } from 'api';
import { getCustomerName } from "lib";
import { useIntl } from "react-intl";

function SelectEmployedCustomers(props, ref) {
    const [ customers, setCustomers ] = useState([]);
    // const [ loading, enableLoading ] = useState( false );
    const { urlParams } = props;
    const translate = useIntl().formatMessage;

    useEffect(()=>{
      if(props.setClick){
        props.setClick(clearCustomersList);
      }
    },[props.setClick ? props.setClick : ''])

    const clearCustomersList  = () => {
      setCustomers([]);
    }

    const handleSearch = (value) => {
        if(!value){
          return false;
        }
        // enableLoading(true);
        Api.get(`${endpoints.customers}?${urlParams || ""}is_active=true&search=${value}`)
          .then(response => {
            const {
              data: { results }
            } = response;
    
            setCustomers(results);
            // enableLoading(false);
          }).catch(error => console.log(error));
    }

    const customersListSource = customers.map(customer => {
      return  ({
        value: customer.id,
        text: getCustomerName(customer)
      });
      /* if(customer.type == 1){
        return  ({
          value: customer.id,
          text: getFullName(customer.first_name,customer.last_name)
        });
      } else if(customer.type == 2){
        return  ({
          value: customer.id,
          text: `${customer.company_name}`
        });
      } */
    });

    return (
        <AutoComplete
            // loading={loading} 
            {...props}
            onSearch={handleSearch}
            dataSource={customersListSource}
            onFocus={()=>{
              setCustomers([]);
            }}
            >
            <InputSearch 
              size={props.size ? props.size : 'large'} 
              placeholder={`${ translate({ id: "search.customers" }) }`}  />
        </AutoComplete>
    );
}

const SelectEmployedCustomer = forwardRef(SelectEmployedCustomers);

export default SelectEmployedCustomer;