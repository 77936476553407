import styled from "styled-components";
import { palette } from "styled-theme";

const AntTab = ComponentName => styled(ComponentName)`
  &.ant-tabs {
    color: ${palette('secondary', 1)};
    &:not(.ant-tabs-vertical) {
      > .ant-tabs-content-animated {
        display: ${props => (props["data-rtl"] === "rtl" ? "block" : "flex")};
      }
    }

    .ant-tabs-nav {
      .ant-tabs-tab {
        margin: ${props =>
          props["data-rtl"] === "rtl" ? "0 0 0 24px" : "0 24px 0 0"};

        .anticon:not(.anticon-close) {
          margin: ${props =>
            props["data-rtl"] === "rtl" ? "0 0 0 8px" : "0 8px 0 0"};

          &.anticon-close{
            right: ${props =>
              props["data-rtl"] === "rtl" ? "inherit" : "2px"};;
            left: ${props =>
              props["data-rtl"] === "rtl" ? "2px" : "inherit"};;
          }
        }
      }
    }

    .ant-tabs-tab-prev {
      left: ${props => (props["data-rtl"] === "rtl" ? "inherit" : "0")};
      right ${props => (props["data-rtl"] === "rtl" ? "2px" : "inherit")};
      transform: ${props =>
        props["data-rtl"] === "rtl" ? "rotate(180deg)" : "rotate(0)"};

    }

    .ant-tabs-tab-next {
      left: ${props => (props["data-rtl"] === "rtl" ? "0" : "inherit")};
      right ${props => (props["data-rtl"] === "rtl" ? "inherit" : "2px")};
      transform: ${props =>
        props["data-rtl"] === "rtl" ? "rotate(180deg)" : "rotate(0)"};
    }

    &.ant-tabs-vertical{
      .ant-tabs-tab-prev,
      .ant-tabs-tab-next{
        transform: rotate(0);
      }
    }

    .ant-tabs-nav-container {
      padding-left: 30px;
      .ant-tabs-nav {
        .ant-tabs-tab:first-child {
          padding: 10px 15px;
        }
        .ant-tabs-tab {
          margin: 0;
          padding: 10px 25px;
          margin-top: 15px;
          font-weight: 700;
          font-size: 16px;
          color:  ${palette("text", 5)};
        }
        .ant-tabs-tab-active {
          color: ${palette("primary", 0)};
        }
        .ant-tabs-ink-bar{
          background-color:${palette("primary", 0)};
          height: 3.5px;
        }
        .ant-tabs-tab:hover {
          color:  ${palette("text", 5)};
        }
        .ant-tabs-tab-active:hover{
          color: ${palette("primary", 0)};
        }
      }
    }
    .ant-tabs-content {
      .br {
        border-bottom: 1px solid ${palette("border", 2)};
        text-align: center;
        margin: 5px 0 15px;
        height: 1px;
      }
      .ant-tabs-tabpane {
        padding: 5px 30px !important;
      }
    }
    
  }
`;

export default AntTab;
