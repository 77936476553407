import React, { Component } from "react";
import { connect } from "react-redux";
import Popover from "../../components/uielements/popover";
import IntlMessages from "../../components/utility/intlMessages";
import authAction from "../../redux/auth/actions";
import TopbarDropdownWrapper from "./topbarDropdown.style";
import { Icon } from "antd";
import { Link } from "react-router-dom";
import { APP_PATH } from "../App/Router/paths";
import { Translate } from "components/utility";

const { logout } = authAction;

class TopbarUser extends Component {
  constructor(props) {
    super(props);
    this.handleVisibleChange = this.handleVisibleChange.bind(this);
    this.hide = this.hide.bind(this);
    this.state = {
      visible: false
    };
  }
  hide() {
    this.setState({ visible: false });
  }
  handleVisibleChange() {
    this.setState({ visible: !this.state.visible });
  }

  render() {
    const content = (
      <TopbarDropdownWrapper className="isoUserDropdown">
        <Link className="isoDropdownLink" to="#">
          {this.props.user_name}
        </Link>
        <Link className="isoDropdownLink" to={APP_PATH.profile}>
          <Translate id={"profile"}/>
        </Link>
        <Link
          className="isoDropdownLink"
          to={"/login"}
          onClick={this.props.logout}
        >
          <IntlMessages id="topbar.logout" />
        </Link>
      </TopbarDropdownWrapper>
    );

    return (
      <Popover
        content={content}
        trigger="click"
        visible={this.state.visible}
        onVisibleChange={this.handleVisibleChange}
        arrowPointAtCenter={true}
        placement="bottomLeft"
      >
        <div className="isoImgWrapper">
          <Icon type="user" />
          {/* <img alt="user" src={userpic} /> */}
          <span className="userActivity online" />
        </div>
      </Popover>
    );
  }
}
export default connect(
  state => ({
    user_name: `${state.Profile.user.first_name} ${state.Profile.user.last_name}`
  }),
  { logout }
)(TopbarUser);
