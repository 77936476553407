import styled from 'styled-components';
import { palette } from 'styled-theme';

const Cards = ComponentName  => styled(ComponentName)`
    &.ant-card{
        color: ${palette('secondary', 1)};
        border-radius: 13px;
        border: 0;
        &.primary{
            background-color: ${palette('secondary', 1)};
        }
        &.secondary{
            background-color: ${palette('secondary', 0)};
        }
        &.default{
            background-color: #FFFFFF;
            .title{
                color: #000;
            }
            .ant-card-head-title{
                color: #000;
            }
        }
        .ant-card-head{
            padding: 0 30px;
            border-bottom: 1px solid #E5E5E5;
        }
        .ant-card-head-title{
            font-size: 22px;
            font-weight: normal;
            color: #fff;
            padding: 17px 0 13px;
        }
        .title{
            color: #fff;
        }
        .ant-card-body{
            padding: 15px;
        }
        &.has-tab{
          .ant-card-body{
            padding: 0 0 15px 0;
          }
        }
    }
`;

export { Cards }