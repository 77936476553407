
const actions = {
    CUSTOMER_LIST: 'CUSTOMER_LIST',
    SET_CUSTOMER: 'SET_CUSTOMER',
    set_customers: (payload) => {
        return {
            type: actions.CUSTOMER_LIST,
            payload
        };
    },
    set_customer: (payload) => {
        return {
            type: actions.SET_CUSTOMER,
            payload
        };
    }
};

export default actions;