import actions from './actions';
const initState = { list: [], customer: {} };

export default function customersReducer(state = initState, action){
    switch (action.type) {
        case actions.CUSTOMER_LIST:
            // console.log(state,action.payload)
            return {...state, list: action.payload}
        case actions.SET_CUSTOMER:
            return {...state, customer: action.payload}
        default:
            return state;
    }
}