import React, { Component } from "react";
import { connect } from "react-redux";
import ServicesAction from "redux/services/actions";
import Form from "components/uielements/form";
import { 
  Button, 
  Select, 
  SelectOption, 
  Input, 
  InputNumber,  
  Modal
} from "components/uielements";
import Api from "api/Api";
import { endpoint } from "api/endpoint";
import notification from "components/notification";
import { servicesUnitList, getFullName} from "lib";
import ServicesListContext from "../../Context/services/ServicesListContext";
import { SERVICE_VATS } from "../../../../constants";
import { Translate } from "components/utility";
import { SelectSelfEmployeds } from "../../_SelectEntities";
import { injectIntl } from 'react-intl';

const FormItem = Form.Item;
const { modal_toggle } = ServicesAction;

class CreateServices extends Component {
  constructor(props) {
    super(props);
    this.state = {
      serviceId: "",
      loading: false
    };
    this.setFieldsValue = props.form.setFieldsValue;
  }

  componentWillReceiveProps(nextProps, prevState) {
    if (nextProps.service !== this.props.service) {
      let service = nextProps.service;
      if (service.id) {
        this.setState({ serviceId: service.id });
        const {
          owner,
          name,
          unit,
          vat,
          rate
        } = service;
        this.setFieldsValue({
          owner: { key: owner.id, label: getFullName(owner.first_name, owner.last_name)},
          name,
          unit,
          vat: String(vat),
          rate
        });

      } else {
        this.formReset();
      }
    }
  }

  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        const { intl: { messages } } = this.props;
        const { owner } = values;
        values["currency"] = "Kr";
        values["owner"] = owner.key;
        this.setState({ loading: true });
        if (this.state.serviceId) {
          Api.put(`${endpoint.services}${this.state.serviceId}/`, values)
            .then(response => {
              notification("success", `${messages["feedback.servicesEdit"]}`);
              this.context.get_services();
              this.setState({ loading: false });
              this.handleCancel();
            })
            .catch(error => {
              this.setState({ loading: false });
            });
        } else {
          Api.post(endpoint.services, values)
            .then(response => {
              notification("success", `${messages["feedback.servicesCreate"]}`);
              this.context.get_services();
              this.setState({ loading: false });
              this.handleCancel();
            })
            .catch(error => {
              this.setState({ loading: false });
            });
        }
      }
    });
  };

  formReset = () => {
    this.props.form.resetFields();
  };

  handleCancel = e => {
    this.props.modal_toggle();
  };

  render() {
    const {
      state: { serviceId, loading }
    } = this;
    const { getFieldDecorator } = this.props.form;
    let title;
    if (serviceId) {
      title = <Translate id={"services.editTitle"} />;
    } else {
      title = <Translate id={"services.createTitle"} />;
    }
    return (
      <ServicesListContext.Consumer>
        {context => (
          <Modal
            wrapClassName="default-modal"
            title={title}
            visible={this.props.isModal}
            onCancel={this.handleCancel}
            footer={null}
          >
            <div className="CreateCustomerForm">
              <Form onSubmit={this.handleSubmit} className="formWithlable">
                <FormItem label={<Translate id={"label.selfEmployed"} />}>
                  {getFieldDecorator("owner", {
                    rules: [
                      {
                        required: true,
                        message: <Translate id={"form.requiredMessage"} />
                      }
                    ]
                  })(<SelectSelfEmployeds
                    dropdownClassName={'employedList'}
                      labelInValue
                    />)}
                </FormItem>
                <FormItem label={<Translate id={"label.serviceName"} />}>
                  {getFieldDecorator("name", {
                    rules: [
                      {
                        required: true,
                        message: <Translate id={"form.requiredMessage"} />
                      }
                    ]
                  })(<Input type="text" size="large" name="name" id="name" />)}
                </FormItem>
                <FormItem label={<Translate id={"label.unit"} />}>
                  {getFieldDecorator("unit", {
                    rules: [
                      {
                        required: true,
                        message: <Translate id={"form.requiredMessage"} />
                      }
                    ],
                    initialValue: "Förp"
                  })(
                    <Select name="unit" id="unit">
                      {servicesUnitList().map((item, index) => {
                        return (
                          <SelectOption key={index} value={item.value}>
                            {item.name}
                          </SelectOption>
                        );
                      })}
                    </Select>
                  )}
                </FormItem>
                <FormItem label={<Translate id={"label.vat"} />}>
                  {getFieldDecorator(`vat`, { initialValue: "0.00" })(
                    <Select name="vat" id="vat">
                      {SERVICE_VATS.map((vat, key) => (
                        <SelectOption value={vat.value} key={key}>
                          {vat.label}
                        </SelectOption>
                      ))}
                    </Select>
                  )}
                </FormItem>
                <FormItem label={<Translate id={"label.taskRate"} />}>
                  {getFieldDecorator("rate", {
                    rules: [
                      {
                        required: true,
                        message: <Translate id={"form.requiredMessage"} />
                      }
                    ]
                  })(<Input name="rate" id="rate" suffix="SEK/h"/>)}
                </FormItem>
                <div className="isoInputWrapper footer-btn">
                  <Button
                    htmlType="submit"
                    className="primary"
                    loading={loading}
                  >
                    <Translate id="button.save" />
                  </Button>
                  <Button className="default" onClick={this.handleCancel}>
                    <Translate id="button.cancel" />
                  </Button>
                </div>
              </Form>
            </div>
          </Modal>
        )}
      </ServicesListContext.Consumer>
    );
  }
}

CreateServices.contextType = ServicesListContext;

const WrappedCreateServices = Form.create()(CreateServices);

const mapStateToProps = function(state) {
  return {
    isModal: state.Services.isModal,
    service: state.Services.service
  };
};

const mapDispatchToProps = {
  modal_toggle: modal_toggle
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(WrappedCreateServices));
