import actions from "./actions";
const initState = { self_employed: {} };

export default function selfEmployedReducer(state = initState, action) {
  switch (action.type) {
    case actions.SET_SELF_EMPLOYED:
      return { ...state, self_employed: action.payload };
    default:
      return state;
  }
}
