import { language } from "../../settings";

import englishLang from "../../image/flag/uk.svg";
import swedishLang from "../../image/flag/spain.svg";

const ENGLISH_LANGUAGE_ID = "english";
const SWEDISH_LANGUAGE_ID = "swedish";

const config = {
  defaultLanguage: language,
  options: [
    {
      languageId: ENGLISH_LANGUAGE_ID,
      locale: "en",
      text: "English",
      icon: englishLang
    },
    {
      languageId: SWEDISH_LANGUAGE_ID,
      locale: "sv",
      text: "Swedish",
      icon: swedishLang
    }
  ]
};

export function getCurrentLanguage(lang) {
  let selecetedLanguage = config.options[0];
  config.options.forEach(language => {
    if (language.languageId === lang) {
      selecetedLanguage = language;
    }
  });
  return selecetedLanguage;
}

export default config;
export {
  ENGLISH_LANGUAGE_ID, SWEDISH_LANGUAGE_ID
}
