import React from "react";
import ServicesListProvider from "./Context/services/ServicesListProvider";
import {StatisticsProvider} from './Context';

const AppProvider = ({children}) => (
    <ServicesListProvider>
        <StatisticsProvider>
            {children}
        </StatisticsProvider>
    </ServicesListProvider>
);

export default AppProvider;
