import actions from "./actions";
const initState = { user: {} };

export default function profileReducer(state = initState, action) {
  switch (action.type) {
    case actions.SET_USER:
      return { ...state, user: action.payload };
    default:
      return state;
  }
}
