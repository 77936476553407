import React, {useState, useEffect, forwardRef} from 'react';
import { Select, SelectOption } from 'components/uielements';
import api, { endpoints } from 'api';

function SelectServices( props, ref ) {
    const [ services, setServices ] = useState([]);
    const [ loading, enableLoading ] = useState( false );

    const { placeholder, onChange } = props;

    useEffect(() => {
        enableLoading( true );
        api.get( endpoints.services )
            .then( response => {
                setServices( response.data.results );
                enableLoading( false );
            })
            .catch( error => {
                setServices([]);
                enableLoading( false );
            })
    }, []);

    const options = services.map(( service, key ) => (
        <SelectOption key={key} value={service.id}>{service.name}</SelectOption>
    ));

    return (
        <Select
            loading={ loading }
            placeholder={ placeholder || "Select service"}
            onChange={ onChange }
            ref={ref}
        >
            {options}
        </Select>
    );
}

SelectServices = forwardRef( SelectServices );

export default SelectServices;