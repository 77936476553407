import { APP_PATH } from "containers/App/Router/paths";
const options = [
  {
    key: APP_PATH.dashboard,
    label: "sidebar.dashboard",
    leftIcon: "dashboard",
    type: 2 //self-employe
  },
  {
    key: "users",
    label: "sidebar.users",
    leftIcon: "self-employed",
    type: 1, // admin
    children: [
      {
        key: APP_PATH.self_employed_list,
        label: "sidebar.selfEmployed"
      },
      {
        key: APP_PATH.self_employed_pending_list,
        label: "sidebar.selfEmployedPending"
      }
    ]
  },
  {
    key: APP_PATH.customers_list,
    label: "sidebar.customers",
    leftIcon: "customers",
    type: 2
  },
  {
    key: APP_PATH.servicesList,
    label: "sidebar.services",
    leftIcon: "services",
    type: 2
  },
  {
    key: APP_PATH.invoices_list,
    label: "sidebar.invoice",
    leftIcon: "invoice",
    type: 2
  },
  {
    key: APP_PATH.transaction_list,
    label: "sidebar.transaction",
    leftIcon: "transaction",
    type: 2
  },
  {
    key: APP_PATH.register_salary,
    label: "sidebar.registerSalary",
    leftIcon: "registerSalary",
    type: 2
  },
  {
    key: APP_PATH.earnings,
    label: "sidebar.earnings",
    leftIcon: "earnings",
    type: 1
  },
  {
    key: APP_PATH.expenditure,
    label: "sidebar.expenditure",
    leftIcon: "expenditure",
    type: 2
  },
  {
    key: APP_PATH.document,
    label: "sidebar.document",
    leftIcon: "document",
    type: 2
  },
  {
    key: APP_PATH.termsCondition,
    label: "sidebar.termsCondition",
    leftIcon: "termsCondition",
    type: 1
  },
  /*{
    key: APP_PATH.settings,
    label: "sidebar.settings",
    leftIcon: "settings",
    type: 2
  } */
];
export default options;
