export const APP_PATH = {
  dashboard: `/dashboard/`,
  create_self_employed: `/create-self-employed/`,
  self_employed_list: `/self-employed/`,
  self_employed_pending_list: `/self-employed-pending/`,
  self_employed_details: `/self-employed-details/`,
  customers_list: `/customers/`,
  create_customer: `/customer/create/`,
  customer_edit: `/customer/edit/`,
  customer_details: `/customer/details/`,
  servicesList: `/services/`,
  invoices_list: `/invoice/`,
  create_invoice: `/invoice/create/`,
  update_invoice: `/invoice/update/`,
  invoice_details: `/invoice/details/`,
  transaction_list: `/transaction/`,
  transaction_history: `/transaction-history/`,
  register_salary: `/register-salary/`,
  register_salary_se: `/register-salary/create/`,
  salary_details: `/salary/details/`,
  profile: `/profile/`,
  settings: `/settings/`,
  earnings: '/earnings/',
  employeeEarnings: '/earnings/self-employed/',
  expenditure: '/expenditure/',
  expenditure_create: `/expenditure/create/`,
  expenditure_details: `/expenditure/details/`,
  document: `/document/`,
  termsCondition: `/termsCondition/`,
};
