import styled from "styled-components";

const LayoutContentWrapper = styled.div`
  padding: 100px 50px 30px;
  @media only screen and (max-width: 768px) {
    padding: 70px 15px 30px 35px;
  }
  @media (max-width: 580px) {
    padding: 70px 20px 20px;
  }
`;

export { LayoutContentWrapper };
