import React from "react";
import { Provider, connect } from "react-redux";
import { store, history } from "./redux/store";
import PublicRoutes from "./router";
import { ThemeProvider } from "styled-components";
import { ConfigProvider } from "antd";
import { IntlProvider } from "react-intl";
import themes from "./settings/themes";
import AppLocale from "./languageProvider";
import config, {
  getCurrentLanguage
} from "./containers/LanguageSwitcher/config";
import { themeConfig } from "./settings";
import DashAppHolder from "./dashAppStyle";
import Boot from "./redux/boot";

if( !Intl.PluralRules ){
    require('@formatjs/intl-pluralrules/polyfill');
    require('@formatjs/intl-pluralrules/dist/locale-data/en');
    require('@formatjs/intl-pluralrules/dist/locale-data/sv');
}

if( !Intl.RelativeTimeFormat ){
    require('@formatjs/intl-relativetimeformat/polyfill');
    require('@formatjs/intl-relativetimeformat/dist/locale-data/en');
    require('@formatjs/intl-relativetimeformat/dist/locale-data/sv');
}

const currentAppLocale =
  AppLocale[getCurrentLanguage(config.defaultLanguage || "english").locale];

const DashApp = () => (
    <Provider store={store}>
        <ConfigProvider locale={currentAppLocale.antd}>
            <App/>
        </ConfigProvider>
    </Provider>
);

const App = connect(mapStateToProps)(({ appLanguage }) => {
    const currentAppLocale = AppLocale[getCurrentLanguage(appLanguage).locale];

    return(
        <IntlProvider
            locale={currentAppLocale.locale}
            messages={currentAppLocale.messages}
        >
            <ThemeProvider theme={themes[themeConfig.theme]}>
                <DashAppHolder>
                    <PublicRoutes history={history}/>
                </DashAppHolder>
            </ThemeProvider>
        </IntlProvider>
    )
});

function mapStateToProps( state ){
    return{
        appLanguage: state.Settings.locale
    }
}

Boot()
  .then(() => DashApp())
  .catch(error => console.error(error));

export default DashApp;
export { AppLocale };
