import styled from "styled-components";
import { palette } from "styled-theme";
import { transition } from "../../../settings/style-util";

const Pagination = ComponentName => styled(ComponentName)`
  &.ant-pagination {
    float: right;
    margin: 16px 0;
    @media (max-width: 580px) {
      float: none;
    }
    .ant-pagination-item {
      border-color: ${palette("primary", 0)};
      min-width: 38px;
      height: 38px;
      line-height: 38px;
      border-radius: 3px;

      a {
        font-size: 16px;
        color: ${palette("primary", 0)};
      }

      margin: ${props =>
        props["data-rtl"] === "rtl" ? "3px 0 3px 10px" : "3px 10px 3px 0"};

      &.ant-pagination-item-active {
        background-color: ${palette("primary", 0)};
        border-color: ${palette("primary", 0)};

        a {
          color: #ffffff;
        }

        &:hover,
        &:focus {
          background-color: ${palette("primary", 1)};
          border-color: ${palette("primary", 1)};
          a {
            color: #ffffff;
          }
        }
      }

      &:hover {
        border-color: ${palette("primary", 0)};
        ${transition()};
      }

      &:hover a {
        color: ${palette("primary", 0)};
      }
    }
    .ant-pagination-total-text {
      margin: ${props =>
        props["data-rtl"] === "rtl" ? "3px 0 3px 10px" : "3px 10px 3px 0"};
      @media (max-width: 580px) {
        display: flex;
      }
    }

    .ant-pagination-prev,
    .ant-pagination-next,
    .ant-pagination-jump-prev,
    .ant-pagination-jump-next {
      min-width: 38px;
      height: 38px;
      font-size: 16px;
      line-height: 36px;
      border-radius: 3px;
      margin: ${props =>
        props["data-rtl"] === "rtl" ? "3px 0 3px 10px" : "3px 10px 3px 0"};
    }

    .ant-pagination-next {
      margin-right: 0;
    }

    .ant-pagination-prev:hover,
    .ant-pagination-next:hover {
      border-color: ${palette("primary", 0)};

      a {
        color: ${palette("primary", 0)};
      }
    }

    .ant-pagination-prev .ant-pagination-item-link,
    .ant-pagination-next .ant-pagination-item-link {
      border-color: ${palette("primary", 0)};
      transform: ${props =>
        props["data-rtl"] === "rtl" ? "rotate(180deg)" : "rotate(0)"};
    }
    .ant-pagination-item-link .anticon svg {
      fill: ${palette("primary", 0)};
    }
    .ant-pagination-jump-prev,
    .ant-pagination-jump-next {
      &:after {
        transform: ${props =>
          props["data-rtl"] === "rtl"
            ? "rotate(180deg) scale(0.66666667)"
            : "rotate(0) scale(0.66666667)"};
      }
    }

    &.ant-pagination-simple {
      .ant-pagination-prev,
      .ant-pagination-next {
        margin: 0;
      }

      .ant-pagination-simple-pager {
        margin: ${props =>
          props["data-rtl"] === "rtl" ? "3px 0 3px 10px" : "3px 10px 3px 0"};
      }
    }

    .ant-pagination-options {
      margin: ${props =>
        props["data-rtl"] === "rtl" ? "3px 10px 3px 0" : "3px 0 3px 10px"};

      .ant-select .ant-select-selection.ant-select-selection--single {
        height: 32px;
        border-color: ${palette("primary", 0)};
        color: ${palette("primary", 0)};
        .ant-select-selection__rendered {
          line-height: 28px;
        }
        .ant-select-arrow-icon {
          color: ${palette("primary", 0)};
        }
      }

      .ant-pagination-options-size-changer {
        margin: 0 1px 0 0;
      }
    }
  }
`;

export default Pagination;
