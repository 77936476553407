const actions = {
    SERVICES_MODAL: 'SERVICES_MODAL',
    SERVICES_LIST: 'SERVICES_LIST',
    SERVICE: 'SERVICE',
    modal_toggle: (payload) => {
        return {
            type: actions.SERVICES_MODAL,
            payload
        }
    },
    set_services: (payload) => {
        return {
            type: actions.SERVICES_LIST,
            payload
        };
    },
    set_service: (payload) => {
        return {
            type: actions.SERVICE,
            payload
        };
    }
}
export default actions;