import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect
} from "react-router-dom";
import { ConnectedRouter } from "react-router-redux";
import { connect } from "react-redux";

import App from "./containers/App/App";
import asyncComponent from "./helpers/AsyncFunc";

const RestrictedRoute = ({ component: Component, isLoggedIn, ...rest }) => (
  <Route
    {...rest}
    render={props =>
      isLoggedIn ? (
        <Component {...props} />
      ) : (
        <Redirect
          to={{
            pathname: "/login",
            state: { from: props.location }
          }}
        />
      )
    }
  />
);
const PublicRoutes = ({ history, isLoggedIn }) => {
  return (
    <ConnectedRouter history={history}>
      <Router>
        <Switch>
          <Route
            exact
            path={"/login"}
            component={asyncComponent(() => import("./containers/Page/SELoginEmail"))}
          />
          <Route
            exact
            path={"/admin-login"}
            component={asyncComponent(() => import("./containers/Page/AdminLoginEmail"))}
          />
          <Route
            exact
            path={"/login-bankid"}
            component={asyncComponent(() =>
              import("./containers/Page/SELoginBankid")
            )}
          />
          <Route
            exact
            path={"/admin-login-bankid"}
            component={asyncComponent(() =>
              import("./containers/Page/AdminLoginBankid")
            )}
          />
          <Route
            exact
            path={"/registration"}
            component={asyncComponent(() =>
              import("./containers/Page/Registration")
            )}
          />
          <Route
            exact
            path={"/registration-message"}
            component={asyncComponent(() =>
              import("./containers/Page/Message")
            )}
          />
          <Route
            exact
            path={"/change-password/:id/:token/"}
            component={asyncComponent(() =>
              import("./containers/Page/ChangePassword")
            )}
          />
          <Route
              exact
              path={"/recover-password"}
              component={asyncComponent(() =>
                  import("./containers/Page/ForgetPassword")
              )}
          />
          <Route
              exact
              path={"/terms-condition"}
              component={asyncComponent(() =>
                  import("./containers/Page/TermsConditions")
              )}
          />
          <RestrictedRoute exact component={App} isLoggedIn={isLoggedIn} />
        </Switch>
      </Router>
    </ConnectedRouter>
  );
};

export default connect(state => ({
  isLoggedIn: state.Auth.idToken !== null
}))(PublicRoutes);
