import antDesignEnglishTexts from 'antd/lib/locale-provider/en_US';
import enMessages from '../locales/en_US.json';

const EnglishTexts = {
  messages: {
    ...enMessages,
  },
  antd: antDesignEnglishTexts,
  locale: 'en-US'
};
export default EnglishTexts;
