import Api from "api/Api";
import { endpoint } from "api/endpoint";
import AppAction from "../app/actions";

const actions = {
  CHECK_AUTHORIZATION: "CHECK_AUTHORIZATION",
  LOGIN_REQUEST: "LOGIN_REQUEST",
  LOGOUT: "LOGOUT",
  LOGIN_SUCCESS: "LOGIN_SUCCESS",
  LOGIN_ERROR: "LOGIN_ERROR",
  checkAuthorization: () => ({ type: actions.CHECK_AUTHORIZATION }),
  login: payload => {
    return {
      type: actions.LOGIN_REQUEST,
      payload
    };
  },
  logout: () => {
    return function(dispatch, store) {
      /* dispatch({
        type: AppAction.CHANGE_CURRENT,
        current: ["dashboard"]
      }); */
      dispatch({
        type: actions.LOGOUT
      });
      return Api.delete(endpoint.auth);
    };
  },
  loginAction: payload => {
    return function(dispatch, store) {
      return Api.post(endpoint.auth, payload);
    };
  }
};
export default actions;
