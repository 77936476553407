import { all, takeEvery, put, fork } from "redux-saga/effects";
import { push } from "react-router-redux";
import { getToken, clearToken } from "../../helpers/utility";
import { store } from "../store";
import actions from "./actions";
import profileActions from "../profile/actions";
import Api from "api/Api";

const fakeApiCall = true; // auth0 or express JWT

export function* loginRequest() {
  yield takeEvery("LOGIN_REQUEST", function*({ payload }) {
    if (fakeApiCall) {
      yield put({
        type: actions.LOGIN_SUCCESS,
        token: payload.token,
        user_type: payload.user_type,
        user_id: payload.id
      });
    } else {
      yield put({ type: actions.LOGIN_ERROR });
    }
  });
}

export function* loginSuccess() {
  yield takeEvery(actions.LOGIN_SUCCESS, function*(payload) {
    Api.defaults.headers.common["Authorization"] = `token ${payload.token}`;
    yield localStorage.setItem("auth", JSON.stringify(payload));
  });
}

export function* loginError() {
  yield takeEvery(actions.LOGIN_ERROR, function*() {});
}

export function* logout() {
  yield takeEvery(actions.LOGOUT, function*() {
    clearToken();
    yield put(push("/"));
  });
}
export function* checkAuthorization(dispatch) {
  yield takeEvery(actions.CHECK_AUTHORIZATION, function*() {
    const authInfo = getToken();
    if (authInfo) {
      yield put({
        type: actions.LOGIN_SUCCESS,
        token: authInfo.token,
        user_type: authInfo.user_type,
        user_id: authInfo.user_id
      });
      store.dispatch(profileActions.get_user(authInfo.user_id));
    }
  });
}
export default function* rootSaga() {
  yield all([
    fork(checkAuthorization),
    fork(loginRequest),
    fork(loginSuccess),
    fork(loginError),
    fork(logout)
  ]);
}
