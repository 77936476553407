import React from "react";
import { Modal } from "antd";
import { injectIntl } from 'react-intl';

const confirm = Modal.confirm;

const confirmModal = (title, okText, cancelText, callBack) => {

  return confirm({
      title,
      className: "confirm-primary-modal",
      okText: okText, 
      cancelText: cancelText,
      width: "500px",
      onOk() {
        callBack();
      },
      /* onCancel() {
        console.log("Cancel");
      } */
  });
}

export default confirmModal;