import styled from 'styled-components';
import { palette } from 'styled-theme';

const DropdownWrapper = ComponentName => styled(ComponentName)`
  display: inline-block;

  &.ant-dropdown-button {
    .ant-btn{
        line-height: 0;
        padding: 13px 28px;
        font-size: 16px;
        border-radius: 3px;
        height: 44px;
    }
    
    &.dropdown-solid{
      .ant-btn{
        background-color: ${palette('primary', 0)};
        border-color: ${palette('primary', 0)};
        color: #fff;
      }
      
      .ant-btn.ant-dropdown-trigger.ant-btn-default{
        border-left-color: #fff;
        vertical-align: bottom;
      }
      
      &.btn-warning{
        .ant-btn{
          background-color: ${palette('warning', 0)};
          border-color: ${palette('warning', 0)};
        }
      }
      
      &.btn-warning:hover{
        .ant-btn{
          background-color: ${palette('warning', 1)};
          border-color: ${palette('warning', 1)};
        }
        
        .ant-btn.ant-dropdown-trigger.ant-btn-default{
          border-left-color: #fff;
        }
      }
    }
    
    &.dropdown-solid:hover{
      .ant-btn:hover{
         border-right-color: #fff;
      }
    }
    
    &.ant-btn-group > .ant-btn:first-child:not(:last-child) {
      border-radius: ${props =>
        props['data-rtl'] === 'rtl' ? '0 4px 4px 0' : '4px 0  0 4px'};
    }

    &.ant-btn-group > .ant-btn:last-child:not(:first-child) {
      border-radius: ${props =>
        props['data-rtl'] === 'rtl' ? '4px 0 0 4px' : '0 4px 4px 0'};
    }

    &.ant-btn-group .ant-btn + .ant-btn {
      margin: ${props => (props['data-rtl'] === 'rtl' ? '0 -1px 0 0' : '0 0 0 -1px')};
    }
  }

  .ant-dropdown-link {
    font-size: 13px;
    color: ${palette('primary', 0)};

    &:hover {
      color: ${palette('primary', 2)};
    }
  }
`;

const DropdownMenus = ComponentName => styled(ComponentName)`
  min-width: 150px;
  
  .ant-dropdown-menu-item {
    a {
      font-size: 13px;
      color: ${palette('text', 1)};
    }
  }

  .ant-dropdown-menu-item,
  .ant-dropdown-menu-submenu-title {
    a {
      font-size: 13px;
      color: ${palette('text', 1)};
    }

    &:hover {
      background-color: ${palette('secondary', 5)};
    }
  }
`;

export default DropdownWrapper;
export { DropdownMenus };
