import actions from './actions';
const initState = { isModal: false, list: [], service: {}};

export default function customersReducer(state = initState, action){
    switch (action.type) {
        case actions.SERVICES_MODAL:
            return {...state, isModal: !state.isModal, service: {}}
        case actions.SERVICES_LIST:
            return {...state, list: action.payload}
        case actions.SERVICE:
            return {...state, service: action.payload}
        default:
            return state;
    }
}