import styled from "styled-components";
import { palette } from "styled-theme";

const borderRadius = "13px";

const AntTable = ComponentName => styled(ComponentName)`
  .ant-table table {
    border-radius: ${`${borderRadius} ${borderRadius} 0 0`};
  }

  .ant-table-content {
    position: relative;
    padding-bottom: 60px;

    &::after {
      position: absolute;
      content: "";
      left: 0;
      right: 0;
      bottom: 0;
      height: 60px;
      background-color: #fff;
      border-radius: ${`0 0 ${borderRadius} ${borderRadius}`};
    }

    .ant-table-placeholder {
      border-radius: 0;
    }
    .ant-table-body {
      @media only screen and (max-width: 1440px) {
        overflow-x: auto;
      }
    }
  }

  .ant-table-thead > tr > th,
  .ant-table-tbody > tr > td,
  .ant-table-footer tr > td, 
  .ant-table-footer tr > th
  {
    background-color: #ffffff;
    border-bottom: 1.5px solid #e5e5e5;
    padding: 16px 25px 14px;
  }

  .ant-table-tbody > tr > td ,
  .ant-table-footer tr th,
  .ant-table-footer tr > td {
    color: #262626;
    border-bottom: 1px solid #e5e5e5;
  }

  .ant-table-thead {
    .ant-table-column-title {
      font-size: 16px;
    }
  }
  .ant-table-footer{
    padding: 0;
    tr > th{
      font-weight: 500
    }
  }
  .ant-table-thead > tr:first-child > th:first-child {
    border-top-left-radius: ${borderRadius};
  }

  .ant-table-thead > tr:first-child > th:last-child {
    border-top-right-radius: ${borderRadius};
  }

  .ant-table-thead
    > tr.ant-table-row-hover:not(.ant-table-expanded-row):not(.ant-table-row-selected)
    > td,
  .ant-table-tbody
    > tr.ant-table-row-hover:not(.ant-table-expanded-row):not(.ant-table-row-selected)
    > td,
  .ant-table-thead
    > tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected)
    > td,
  .ant-table-tbody
    > tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected)
    > td {
    background: ${palette("secondary", 5)};
  }
  .ant-table-tbody > tr > td.services-name-column {
    width: 40%;
  }
  &.invoice-table, &.salary-table{
    .ant-table table {
      border-radius: 3px;
      border: 1px solid #e5e5e5;
      border-bottom: 0;
      overflow: hidden;
    }

    .ant-table-thead > tr > th {
      background-color: #e5e5e5;
      padding: 13px;
      text-align: center;
      border-right: 1px solid #c0c2c3;
    }

    .ant-table-thead .ant-table-column-title {
      font-size: 14px;
    }

    .ant-table-thead > tr:first-child > th:first-child {
      border-top-left-radius: 0;
    }

    .ant-table-thead > tr:first-child > th:last-child {
      border-top-right-radius: 0;
      border-right: 0;
    }

    .ant-table-tbody > tr > td {
      padding: 13px;
    }
  }
  
  &.hide-footer{
    .ant-table-content{
      padding-bottom: 0;
      
      &:after{
        content: none;
      }
    }
  }

  .ant-pagination {
    .ant-pagination-item {
      border-color: ${palette("primary", 0)};
      min-width: 38px;
      height: 38px;
      line-height: 38px;
      border-radius: 3px;
      a {
        font-size: 16px;
        color: ${palette("primary", 0)};
      }
    }
    .ant-pagination-prev .ant-pagination-item-link,
    .ant-pagination-next .ant-pagination-item-link {
      border-color: ${palette("primary", 0)};
    }
    .ant-pagination-item-active {
      background: ${palette("primary", 0)};
      border-color: ${palette("primary", 0)};
      a {
        color: #ffffff;
      }
    }
    .ant-pagination-item-active:focus,
    .ant-pagination-item-active:hover {
      background: ${palette("primary", 0)};
      border-color: ${palette("primary", 0)};
      a {
        color: #ffffff;
      }
    }
    .ant-pagination-prev,
    .ant-pagination-next,
    .ant-pagination-jump-prev,
    .ant-pagination-jump-next {
      min-width: 38px;
      height: 38px;
      font-size: 16px;
      line-height: 36px;
      border-radius: 3px;
      margin: ${props =>
        props["data-rtl"] === "rtl" ? "3px 0 3px 10px" : "3px 10px 3px 0"};
    }
    .ant-pagination-item-link .anticon svg {
      fill: ${palette("primary", 0)};
    }
    .ant-pagination-total-text {
      @media (max-width: 580px) {
        display: flex;
      }
    }
  }
`;

export default AntTable;
