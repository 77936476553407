const actions = {
  SELF_EMPLOYED_LIST: "SELF_EMPLOYED_LIST",
  SET_SELF_EMPLOYED: "SET_SELF_EMPLOYED",
  set_employed: payload => {
    return {
      type: actions.SET_SELF_EMPLOYED,
      payload
    };
  }
};

export default actions;
