import React from 'react';
import ReactDOM from 'react-dom';
import DashApp from './dashApp';
import * as serviceWorker from './serviceWorker';
import * as Sentry from '@sentry/browser';
import 'antd/dist/antd.css';

if(process.env.NODE_ENV != 'development'){
    Sentry.init({dsn: "https://feb784a0911a4595b712ec8b1ed9266e@sentry.io/2429201"});
}

ReactDOM.render(<DashApp />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA

serviceWorker.register();
/* serviceWorker.register({
    onUpdate: registration => {
    // alert('New version available!  Ready to update?');
    window.location.reload();
      if (registration && registration.waiting) {
        registration.waiting.postMessage({ type: 'SKIP_WAITING' });
      }
    }
}); */