import React from 'react';
import { Translate } from "components/utility";
export const APP_BACKGROUND = '#F7F7FF';
export const THEME_COLOR = '#E52513';

export const SERVICE_VATS = [
    { value: "0.00", label: "0%" },
    { value: "6.00", label: "6%" },
    { value: "12.00", label: "12%" },
    { value: "25.00", label: "25%" },
];

export const PAYMENT_TERMS = [
    { value: 0, label: <Translate id={"day"}/> },
    { value: 10, label: <Translate id={"10Days"}/> },
    { value: 15, label: <Translate id={"15Days"}/> },
    { value: 30, label: <Translate id={"30Days"}/> }
];

export const INVOICE_STATUS = {
  "partiallyPaid": 4,
  "credited": 3,
  "sent": 2,
  "draft": 1
};

export const monthly = [
  {x: "Jan", y: 0},
  {x: "Feb", y: 0},
  {x: "Mar", y: 0},
  {x: "Apr", y: 0},
  {x: "May", y: 0},
  {x: "Jun", y: 0},
  {x: "Jul", y: 0},
  {x: "Aug", y: 0},
  {x: "Sep", y: 0},
  {x: "Oct", y: 0},
  {x: "Nov", y: 0},
  {x: "Dec", y: 0},
];

export const yearly = [
  {x: "Jan", y: 0},
  {x: "Feb", y: 0},
  {x: "Mar", y: 0},
  {x: "Apr", y: 0},
  {x: "May", y: 0},
  {x: "Jun", y: 0},
  {x: "Jul", y: 0},
  {x: "Aug", y: 0},
  {x: "Sep", y: 0},
  {x: "Oct", y: 0},
  {x: "Nov", y: 0},
  {x: "Dec", y: 0},
];